<template>
  <div class="ps-page--single">
    <div
      class="ps-vendor-banner bg--cover"
      data-background="/img/bg/vendor.jpg"
      style="background: url('/img/bg/vendor.jpg')"
    >
      <div class="container">
        <h2>Thanks For applying we are processing your shop , will get back to you</h2>
        <a class="ps-btn ps-btn--lg" href="/">Home</a>
      </div>
    </div>


    <div class="ps-section--vendor ps-vendor-best-fees">
            <div class="container">

            
            </div>
        </div>
  </div>
</template>

<script>
import global from "@/mixins/global.js";
import axios from "axios";
export default {
  data() {
    return {
      shop: {
        name: "",
        city: "",
        street: "",
        description: "",
        country: "",
        phone_number: "",
        shop_category_id: "",
        image: "",
      },
      loading: false,
    };
  },
  mixins: [global],
  mounted() {
    this.getShopCategories();
  },
  methods: {
    saveShop() {
      this.loading = true;
      axios.post('merchant_shop',
          {
            name: this.shop.name,
            city: this.shop.city,
            street: this.shop.street,
            description: this.shop.description,
            country: this.shop.country,
            phone_number: this.shop.phone_number,
            shop_category_id: this.shop.shop_category_id,
          },
        ).then((response) => {
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>